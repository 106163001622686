/* eslint-disable react/no-danger */
import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageHeader from '../../components/Layout/PageHeader'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {Inner, InnerSkinny} from '../../components/styles'
import ContentRow from '../../components/shared/ContentRow'
import PageImageCarousel from '../../components/PageImageCarousel/page-image-carousel'
import IconHomes from '../../images/icons/icon-homes.svg'
import IconMoney from '../../images/icons/icon-money-raised.svg'
import IconVolunteers from '../../images/icons/icon-volunteers.svg'

const Wrapper = styled.div`
  .intro {
    margin-bottom: 60px;
  }
`
const MissionInNumbers = styled.div`
  margin: 60px 0;
  .icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    .icon {
      display: inline-block;
      text-align: center;
      width: 150px;
      height: 150px;
      margin: 30px;
      border: 3px solid ${props => props.theme.red};
      border-radius: 50%;
      font-weight: 600;
      img {
        margin-top: 35px;
      }
      .value {
        width: 144px;
        padding: 10px;
        height: 58px;
      }
    }
  }
  @media (max-width: 600px) {
    .icons {
      grid-template-columns: 1fr;
    }
  }
`

const PhilanthropyPage = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 553}) {
        title
        content
        acf {
          intro
          mission_in_numbers_text
          mission_in_numbers_heading
          mission_in_numbers_icons {
            icon
            value
          }
          rows {
            title
            text
            mode
            text_position
            background_image {
              source_url
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 900, srcSetBreakpoints: [400]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          page_slider {
            slider_image {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxHeight: 700, srcSetBreakpoints: [400]) {
                    src
                  }
                }
              }
            }
            slider_image_mobile {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 400, srcSetBreakpoints: [200]) {
                    src
                  }
                }
              }
            }
          }
        }
      }
      seo: wpgraphql {
        pageBy(pageId: 553) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)

  // Convenience variables
  const reducedRow = data.wp.acf.rows.slice(0, data.wp.acf.rows.length - 1)
  const lastRow = data.wp.acf.rows[data.wp.acf.rows.length - 1]

  return (
    <Layout location={location}>
      <SEO location={location} seo={data.seo.pageBy.seo} title="Philanthropy" />
      <PageHeader location={location}>{data.wp.title}</PageHeader>
      <Wrapper>
        <Inner>
          <div
            dangerouslySetInnerHTML={{__html: data.wp.acf.intro}}
            className="intro"
          />
        </Inner>
        {reducedRow.map(row => (
          <ContentRow data={row} key={row.title} />
        ))}
        {data.wp.acf.page_slider && (
          <PageImageCarousel
            images={data.wp.acf.page_slider}
            halfpageContent={lastRow}
          />
        )}
        <MissionInNumbers>
          <InnerSkinny>
            <h2
              className="red"
              dangerouslySetInnerHTML={{
                __html: data.wp.acf.mission_in_numbers_heading,
              }}
            />
            <div className="icons">
              {data.wp.acf.mission_in_numbers_icons.map((icon, i) => (
                <div key={i} className="icon">
                  {icon.icon === 'home' && <img src={IconHomes} alt="" />}
                  {icon.icon === 'money' && <img src={IconMoney} alt="" />}
                  {icon.icon === 'volunteers' && (
                    <img src={IconVolunteers} alt="" />
                  )}
                  <div className="value">{icon.value}</div>
                </div>
              ))}
            </div>
            <div
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: data.wp.acf.mission_in_numbers_text,
              }}
            />
          </InnerSkinny>
        </MissionInNumbers>
      </Wrapper>
    </Layout>
  )
}

export default PhilanthropyPage
